<template>
    <b-container fluid style="background-color: #fff" class="pb-3">
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img :src="orgData.org_profile_img" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>
    </b-row>

    <b-row>
      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="orgData.org_cover_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="orgData.org_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="12" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="orgData.org_cover_img" style="width:100%" />
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="mb-2">
            <span class="primary-color">{{orgData.org_name}}</span>
          </h3>
          <h3 class="mb-0">
            Forgot Password
          </h3>
          <p>
            Enter your email address and we'll send you an email with instructions to reset your password.
          </p>
          <ValidationObserver ref="resetForm">
            <form class="mt-4">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                  <input type="email" class="form-control mb-0" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="userEmail" placeholder="Enter email">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="d-inline-block w-100">
                <button type="button" class="btn btn-primary float-right" @click="resetPassword">
                  Reset Password
                </button>
              </div>
            </form>
          </ValidationObserver>

          <div class="sign-info">
            <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ path: 'login'}">Sign In</router-link></span>
            <ul class="iq-social-media mt-4">
              <li>
                <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
              </li>
            </ul>
          </div>

          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user"
import ApiResponse from "../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import { Organisations } from "../FackApi/api/organisation.js"
export default {
  name: "ForgotPassword",
  components: {
    ValidationProvider
  },
  data: () => ({
    eventDetail: { event_img: "https://gide-assets-prod.s3.amazonaws.com/EVENT/EVEcff7a6d503ee443b86553f529683d4e3/8a48d740-2ee6-11ef-864f-03eb2ac38166.png", event_desc: "Join HSM Connect, a virtual Career Counselling & University Fair scheduled on July 20 & 21, where students and parents from different regions can connect & interact with 20+ universities from across the globe. Students & parents will get to learn about academic programs, scholarships, campus life, entry requirements, timelines & much more." },
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Reset Password",
    userEmail: "",
    showPasswordField: false,
    operatingsystem: window.__DEVICE_OS__,
    orgData: {
      id: 54238,
      org_id: "GIDE_ORG_1",
      user_id: "1",
      acc_id: "1",
      org_name: "GIDE.AI",
      type: "ORG",
      org_desc: `<h1><strong style="color: rgb(226, 38, 44);">GIDE.AI</strong><strong>: </strong>Your Gateway to Scholarships and Career Success</h1><p><strong style="color: rgb(230, 0, 0);">Brought to you by Asia's largest Student and Parent community - High School Moms (HSM)</strong></p><p>Access futuristic career assessment, profile building, test preparation, great scholarships, and career guidance all in one integrated experience.</p><p><br></p>`,
      org_website_email: null,
      org_admin_email: "admin@gide.ai",
      org_admision_email: "admissions@gide.ai",
      org_admission_requirement: null,
      org_top_alums: null,
      org_major_industries_around: null,
      org_clubs: null,
      org_college_basis_app: null,
      org_city: null,
      org_country: null,
      org_founding_year: null,
      org_application_deadline: null,
      org_intake_count: null,
      org_intake_type: null,
      org_currency: null,
      org_avg_tuition_min: null,
      org_avg_tuition_max: null,
      org_scholarship_min: null,
      org_scholarship_max: null,
      org_tnc: 0,
      created_on: "2023-11-22T23:26:47.000Z",
      deleted: 0,
      country_id: null,
      org_profile_img: "https://web.gide.ai/img/GideWhiteNRed.2533ee45.jpeg",
      org_cover_img: "https://gide-assets.s3.amazonaws.com/GIDECover.White.png",
      org_state: null,
      org_uid: "",
      org_domain: "web.gide.ai",
      org_domain_is_validated: 99,
      country_name: null,
      currency_name: null,
      currency_code: null
    },
    propOpenedInModal: false
  }),
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  async mounted () {
    await this.getOrgDetails()
    this.userEmail = this.$route.params.email
  },
  methods: {
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        // Do not get the org_details if running on localhost, for localdns we have to set the org_domain in DB
        if (process.env.VUE_APP_ENV == "local") {
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
          return
        }

        const orgDomain = window.location.hostname
        if (this.saasOrgData) {
          // Found in Cache
          this.orgData = this.saasOrgData
        }
        else {
          const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
          if (!orgDetails.resp_status) {
            // set the details of GIDE_ORG_1 as default
            this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
            return
          }

          this.orgData = orgDetails.resp_data.data
          // set the org data in local storage for saving in user table
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)

          // set the source for the user
          let source = this.orgData.org_uid
          if (this.sourceData) {
            source = this.orgData.org_uid + "_" + this.sourceData
          }

          this.$store.dispatch("SourceTracker/setCodeAction", source)
        }
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    async resetPassword () {
      try {
        this.$store.dispatch("User/setUserProfileAction", { user_email: this.userEmail })
        let resp = await User.userForgotPassword(this, this.userEmail)
        await ApiResponse.responseMessageDisplay(this, resp)
        if (resp.resp_status) {
          setTimeout(() => {
            this.$router.push("/resetPassword")
          }, 500)
        }
      }
      catch (err) {
        console.error("Exception occurred in resetPassword() and Exception:", err.message)
      }
    }
  }
}
</script>
